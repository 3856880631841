import {
  AccountsApi,
  ExtendedAccount,
  GetAccounts200Response,
} from "@practice/sdk";
import useSWR from "swr";

import { useAuth } from "@contexts/auth";

import useOrganizationAccount from "./use-organization-account";
import { useRequestIdGenerator } from "./use-request-id-generator";
import { useSDKApi } from "./use-sdk-api";

export type UseOrganizationAccountsType = (id?: string) => {
  accounts?: ExtendedAccount[];
  loading: boolean;
  mutate: () => Promise<GetAccounts200Response | undefined>;
  fullAccessMembers?: ExtendedAccount[];
  elevatedMembers?: ExtendedAccount[];
  limitedMembers?: ExtendedAccount[];
  hasMoreThanOneMember?: boolean;
};

export const useOrganizationAccounts: UseOrganizationAccountsType = () => {
  const { oid } = useAuth();

  const accountsApi = useSDKApi(AccountsApi);
  const generateRequestId = useRequestIdGenerator("useOrganizationAccounts");

  const swrData = useSWR(
    oid ? `orgAccounts/${oid}` : undefined,
    async () => {
      if (!oid) return;
      return accountsApi.getAccounts({
        organizationId: oid,
        skipIntegrations: true,
        xRequestId: generateRequestId(),
      });
    },
    { dedupingInterval: 600000 }
  );

  const data = swrData?.data?.list;

  const fullAccessMembers = data?.filter((m) => m.accessType === "full");
  const elevatedMembers = data?.filter((m) => m.accessType === "elevated");
  const limitedMembers = data?.filter((m) => m.accessType === "limited");

  const hasMoreThanOneMember = data && data.length > 1;

  return {
    accounts: data,
    loading: !data || swrData.isLoading,
    mutate: swrData.mutate,
    fullAccessMembers,
    elevatedMembers,
    limitedMembers,
    hasMoreThanOneMember,
  };
};

export const useOrganizationIntegrations = () => {
  const { oid } = useAuth();

  const accountsApi = useSDKApi(AccountsApi);
  const generateRequestId = useRequestIdGenerator(
    "useOrganizationIntegrations"
  );

  const swrData = useSWR(
    oid ? `orgIntegrations/${oid}` : undefined,
    async () => {
      if (!oid) return;
      return accountsApi.getAccounts({
        organizationId: oid,
        skipIntegrations: false,
        xRequestId: generateRequestId(),
      });
    },
    { dedupingInterval: 600000 }
  );

  return {
    integrations: swrData?.data?.integrations,
    loading: !swrData.data || swrData.isLoading,
    mutate: swrData.mutate,
  };
};

export const useHasFullAccess = () => {
  const { account } = useOrganizationAccount();
  return account?.accessType === "full";
};

export default useOrganizationAccounts;
