import {
  PackageInstanceCard,
  PackageInstanceCardCyclesInner,
  PackageInstancePublic,
  PackageInstancePublicCyclesInner,
} from "@practice/sdk";

import pluralHelper from "@lib/utils/pluralHelper";

import {
  countPackageAppointments,
  frequencyCopies,
} from "@components/Package/utils";
import { getPackageInstanceInfo } from "@components/PackageInstance/utils";

/**
 * This function extracts the number from a string. It ensures the
 * decimal number is returned as a number with the correct value
 * */
const extractNumber = (value: string) => {
  const regex = /\d+(\.\d+)?/g;
  const matches = value.match(regex);
  return matches ? Number(matches[0]) : 0;
};

export const progressText = (
  packageInstance: PackageInstanceCard | PackageInstancePublic,
  fromCycle?:
    | PackageInstanceCardCyclesInner
    | PackageInstancePublicCyclesInner
    | null
) => {
  const {
    isContentTypeTime,
    totalAppointmentsConsumedInMinutes,
    totalSessionsConsumedInTotalAppointments,
    formattedTimeConsumed,
    isCompleted,
    isRollOver,
    isPackageTypeRecurring,
    isUsagePackage,
  } = getPackageInstanceInfo(packageInstance, true, fromCycle);

  const currentStep = isContentTypeTime
    ? totalAppointmentsConsumedInMinutes
    : totalSessionsConsumedInTotalAppointments;

  const maxAllowedSteps = countPackageAppointments(
    packageInstance,
    isRollOver ? null : "intervals",
    undefined,
    true,
    fromCycle
  );

  const isPackageTypeRecurringTimeBased =
    isPackageTypeRecurring && isContentTypeTime;

  const maxAllowedStepsNumber =
    typeof maxAllowedSteps === "string"
      ? extractNumber(maxAllowedSteps)
      : maxAllowedSteps;
  let maxAllowedTime: number | undefined = undefined;

  if (isPackageTypeRecurringTimeBased) {
    maxAllowedTime =
      maxAllowedStepsNumber < 1.0
        ? maxAllowedStepsNumber * 60
        : maxAllowedStepsNumber;
  }

  const recurringTimeBasedSuffix = isPackageTypeRecurringTimeBased
    ? maxAllowedStepsNumber < 1.0
      ? "minutes"
      : packageInstance?.timeType
    : "";

  const baseText = isContentTypeTime
    ? `${formattedTimeConsumed} of ${
        maxAllowedTime ?? maxAllowedSteps
      } ${recurringTimeBasedSuffix}`
    : `${currentStep} of ${maxAllowedSteps} appointments`;

  const bookedText = `Booked ${baseText}`;
  const completedText = `Completed: ${baseText}`;
  const usageBaseText = isContentTypeTime
    ? `${formattedTimeConsumed}`
    : `${pluralHelper(currentStep, "appointment")}`;
  const usageText = `Booked ${usageBaseText}`;
  return isUsagePackage ? usageText : isCompleted ? completedText : bookedText;
};

export const recurringText = (
  packageInstance: PackageInstanceCard | PackageInstancePublic,
  fromCycle?:
    | PackageInstanceCardCyclesInner
    | PackageInstancePublicCyclesInner
    | null
) => {
  const { isContentTypeTime, isRollOver, isPackageTypeRecurring } =
    getPackageInstanceInfo(packageInstance, true, fromCycle);

  if (!isPackageTypeRecurring || !packageInstance?.frequency) return "";

  const cycles = packageInstance?.cycles || [];
  const cycle = cycles.find((cycle) =>
    fromCycle ? cycle === fromCycle : cycle.isCurrent
  );
  let total = cycle?.frequency || 0;
  const isLessThanOneHour = total < 1.0;
  if (isLessThanOneHour) {
    total *= 60;
  }
  const frequencyType = packageInstance.frequency || "months";
  const frequency = frequencyCopies[frequencyType].abbreviation;

  const rolloverStr = isRollOver ? "+" : "";
  const contentTypeStr = isContentTypeTime
    ? isLessThanOneHour
      ? "min"
      : "h"
    : "";

  return `(${rolloverStr}${total}${contentTypeStr}/${frequency})`;
};
