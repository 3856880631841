import { IntegrationsResponseType } from "api-services/definitions/integrations";
import { gmail_v1 } from "googleapis";
import { flattenDeep } from "lodash";

import { EmailMessage } from "@lib/shared-types";

import domains from "./email-domain-block-list.json";

const parsePart = (part: gmail_v1.Schema$MessagePart) => {
  if (!part) return;

  if (part?.mimeType?.startsWith("multipart")) {
    if (!part.parts) return part.body?.data;

    return part.parts.map(parsePart);
  }

  if (part.mimeType === "text/html") return part?.body?.data;
};

export const getBase64Content = (msg: gmail_v1.Schema$Message) => {
  if (
    msg?.payload?.mimeType?.startsWith("multipart") &&
    msg.payload.parts?.[0]
  ) {
    return msg.payload.parts.map(parsePart);
  }

  return [msg?.payload?.body?.data];
};

export const getMessageContent = (
  msg: gmail_v1.Schema$Message,
  quick: boolean
) => {
  if (quick) return false;

  const contentParts = flattenDeep(getBase64Content(msg));

  const content = contentParts.filter(Boolean)[0];

  if (!content) return "";

  const parsedContent = Buffer.from(content, "base64").toString("utf-8");

  return parsedContent;
};

export const getMessageHeader = (
  msg: gmail_v1.Schema$Message,
  header: string
) => {
  return msg?.payload?.headers?.find((h) => h.name === header)?.value;
};

export const getMessageHeaderDictionary = (msg: gmail_v1.Schema$Message) => {
  return msg?.payload?.headers?.reduce((acc, curr) => ({
    ...acc,
    [curr.name]: curr.value,
  }));
};

export const transformMessage = (
  msg: gmail_v1.Schema$Message,
  emails: string[],
  map: Map<string, string>,
  quick: boolean
): EmailMessage => {
  const headers: any = getMessageHeaderDictionary(msg);
  const from = headers["From"];
  const to = headers["To"];
  const cc = headers["Cc"];
  const bcc = headers["Bcc"];

  const getEmails = (emails: string) =>
    extractEmails(emails).map((email) => email.toLocaleLowerCase().trim());

  const toEmails = getEmails(to);
  const bccEmails = getEmails(bcc);
  const ccEmails = getEmails(cc);
  const fromEmails = getEmails(from);
  const messageId = headers["Message-ID"] || headers["Message-Id"] || null;
  const inReplyTo = headers["In-Reply-To"] || null;

  const emailsToIds = (arr: string[]) =>
    arr
      .filter((e) => !emails.includes(e.trim()))
      .map((e) => map.get(e.trim()))
      .filter(Boolean) || [];

  const toIds = emailsToIds([...toEmails, ...ccEmails, ...bccEmails]);
  const fromIds = emailsToIds(fromEmails);

  const content = getMessageContent(msg, quick);
  const dateHeader = headers["Date"];
  const internalDate = msg.internalDate;

  return {
    content: content || "",
    contentHidden: content === false,
    from: fromEmails,
    to: toEmails,
    cc: ccEmails,
    bcc: bccEmails,
    date:
      dateHeader || internalDate
        ? new Date(dateHeader || Number(internalDate))
        : null,
    subject: headers["Subject"] || "",
    toIds,
    fromIds,
    id: msg.id,
    labelIds: msg.labelIds || [],
    messageId,
    inReplyTo,
  };
};

export const extractEmails = (str: string) => {
  if (!str) return [];
  const re = /[^< ]+(?=>)/g;
  return str
    .split(",")
    .map((s) => s.match(re) || [s])
    .flat();
};

export const getCoachEmails = (coach: any) => {
  return coach?.integrations?.emails?.emails || [];
};

export const getOrganizationEmails = (
  integrations: IntegrationsResponseType
) => {
  return integrations?.emails?.map((e: any) => e.email) || [];
};

export const hasEmail = (coach: any) => {
  return !!coach?.integrations?.emails?.list?.length;
};

export const getEmailIntegrations = (coach: any) => {
  return coach?.integrations?.emails?.list || [];
};

export const isDisposable = (email: string): boolean => {
  const emailDomain = email.split("@")[1];

  return domains.includes(emailDomain);
};
