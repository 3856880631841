import classNames from "classnames";

interface LoadingSpinnerProps {
  className?: string;
  width?: string;
  height?: string;
  variant?: "default" | "transparent";
}

interface PageLoadingSpinnerProps extends LoadingSpinnerProps {
  spinnerClassName?: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  className,
  width = "40",
  height = "40",
  variant = "default",
}) => {
  let src = "/images/ic64_spinner_web_cp.gif";

  if (variant === "transparent") {
    src = "/images/spinner_web_96px_transparent.gif";
  }

  return (
    /* eslint-disable react/no-unknown-property */
    <img
      width={width}
      height={height}
      className={className}
      src={src}
      fetchPriority="high"
      alt="Loading..."
    />
  );
};

export const PageLoadingSpinner: React.FC<PageLoadingSpinnerProps> = ({
  className,
  spinnerClassName,
  ...props
}) => (
  <div
    className={classNames("flex items-center justify-center h-full", className)}
  >
    <LoadingSpinner
      className={spinnerClassName}
      variant="transparent"
      {...props}
    />
  </div>
);

export default LoadingSpinner;
