import { FC, ReactNode } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

type Variant =
  | "regular"
  | "outline"
  | "info"
  | "alert"
  | "error"
  | "white-label"
  | "filled-white-label"
  | "info-green"
  | "dashed";

type SizeTypes = "xs" | "sm";

const sizes: Record<SizeTypes, string> = {
  xs: "text-xs",
  sm: "text-sm",
};

type StyleType = {
  [key in Variant]: string;
};

interface SmallBannerItem {
  Icon?: FC<SVGIconProps>;
  text: ReactNode;
}

export interface SmallBannerProps {
  className?: string;
  variant?: Variant;
  size?: SizeTypes;
  items: SmallBannerItem[];
}

const styles: StyleType = {
  regular: "bg-grey-950 text-black-ink border border-grey-950",
  outline: "bg-white text-grey-500 border border-grey-900",
  info: "bg-blue-950 text-blue-300",
  "info-green": "bg-green-200/10 text-green-200",
  alert: "bg-action-900 text-action-400 border border-action-900",
  error: "bg-peach-950 text-peach-400 border border-peach-950",
  "white-label": "text-foreground border border-foreground/20",
  "filled-white-label": "text-foreground bg-foreground/7",
  dashed: "bg-white text-grey-500 border border-dashed border-grey-800",
};

const SmallBanner: FC<SmallBannerProps> = ({
  className,
  variant = "regular",
  size: textSize = "sm",
  items,
}) => (
  <div
    className={classNames(
      "flex flex-col gap-2 py-2 px-3 rounded-lg",
      styles[variant],
      className
    )}
  >
    {items.map(({ Icon, text }, index) => (
      <div
        key={`small-banner-item-${index}`}
        className="flex items-center gap-2"
      >
        {Icon && <Icon className="flex-none w-6 h-6" />}
        <p className={sizes[textSize]}>{text}</p>
      </div>
    ))}
  </div>
);

export default SmallBanner;
