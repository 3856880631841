import { FC, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { PackageInstanceCard } from "@practice/sdk";
import { PackageSchedulerType } from "api-services/definitions/package-instances";
import moment from "moment";

import { getNormalizedDate } from "@lib/appointments";
import { AppointmentType } from "@lib/data/schemas/appointment";
import { PackageType } from "@lib/data/schemas/packages";
import { getCycleFromDate } from "@lib/models/package-instances/utils";

import { ErrorMessage } from "@components/ErrorMessage";
import { getPackageInstanceInfo } from "@components/PackageInstance/utils";
import { progressText } from "@components/PackageInstances/PackageProgress/utils";
import { OptionType } from "@components/Select/Option";
import FormSelect from "@components/Select/Select/FormSelect";

import PackageCyclePreview from "./PackageCyclePreview";

interface AssignPackageFormFieldsProps {
  appointment: AppointmentType;
  packages: PackageType[];
  packageInstances: Array<
    PackageInstanceCard & {
      ownerId?: string;
    }
  >;
  packageSchedulers: PackageSchedulerType[];
  selectedPackageInstanceId?: string;
  isPackageFieldRequired?: boolean;
  isSchedulerFieldRequired?: boolean;
  isLoading?: boolean;
}

export const getActivePackageInstances = (
  packageInstances: PackageInstanceCard[]
) => {
  const data = packageInstances?.filter(
    (packageInstance: PackageInstanceCard) => {
      const { isCompleted } = getPackageInstanceInfo(packageInstance, true);

      return packageInstance.status === "active" && !isCompleted;
    }
  );

  return data ?? [];
};

const AssignPackageSchedulerFormFields: FC<AssignPackageFormFieldsProps> = ({
  appointment,
  packages,
  packageInstances,
  packageSchedulers,
  selectedPackageInstanceId,
  isPackageFieldRequired = true,
  isSchedulerFieldRequired = true,
  isLoading = false,
}) => {
  const { watch, setValue } = useFormContext();
  const [error, setError] = useState<string | null>(null);
  const packageInstanceId = watch("packageInstanceId");
  const schedulerId = watch("schedulerId");
  const filteredPackages = useMemo(() => {
    return getActivePackageInstances(packageInstances)
      .map((packageObject) => ({
        value: packageObject?.id,
        title: packageObject?.title,
        description: progressText(packageObject),
        icon: packageObject?.icon,
      }))
      .sort((a, b) => a.title.localeCompare(b.title));
  }, [packages, packageInstances]);

  const filteredSchedulers = useMemo(() => {
    return packageSchedulers?.filter((scheduler) => {
      return scheduler.packageInstanceId === packageInstanceId;
    });
  }, [packageSchedulers, packageInstanceId]);

  const packageInstance = packageInstances.find(
    (packageObject) => packageObject.id === packageInstanceId
  );

  const scheduler = filteredSchedulers.find(
    (scheduler) => scheduler.id === schedulerId
  );

  const shouldShowCycle = !!packageInstance?.frequency && !!scheduler;
  const shouldShowSchedulerField =
    packageInstanceId && !!filteredSchedulers?.length && !error;
  const cycleRelated = packageInstance
    ? getCycleFromDate(packageInstance, getNormalizedDate(appointment?.start))
    : null;

  const appointmentStartDate = moment(getNormalizedDate(appointment?.start));

  // we need to force the start of day because we used to not save the time
  // correctly when the package is not recurring based
  const isBeforePackageStartDate = appointmentStartDate.isBefore(
    moment(packageInstance?.startDate).startOf("day")
  );

  useEffect(() => {
    if (!packageInstance || isLoading) return;

    if (isBeforePackageStartDate) {
      const formattedApptDate = appointmentStartDate.format("LL");
      const formattedPackageStartDate = moment(packageInstance.startDate)
        .utc()
        .format("LL");
      setError(
        `This appointment on ${formattedApptDate} can't be added to a package that starts on ${formattedPackageStartDate}`
      );
      setValue("schedulerId", "");
    } else {
      setError(null);
    }

    if (cycleRelated?.isFull) {
      setError(
        "The current cycle is full! Add sessions or change the date of the appointment to add in this package."
      );
      setValue("schedulerId", "");
    }
  }, [packageInstance, cycleRelated, isLoading, isBeforePackageStartDate]);

  useEffect(() => {
    if (selectedPackageInstanceId) {
      setValue("packageInstanceId", selectedPackageInstanceId);
    }
  }, [selectedPackageInstanceId]);

  const renderCyclePreview = packageInstance && shouldShowCycle && (
    <PackageCyclePreview
      className="rounded-md border border-grey-900 p-4"
      packageInstance={packageInstance}
      targetDate={getNormalizedDate(appointment?.start)}
    />
  );

  return (
    <div className="flex flex-col gap-4">
      <FormSelect
        name="packageInstanceId"
        placeholder="Choose a package"
        simpleSearchPlaceholder="Search for packages..."
        options={filteredPackages as unknown as OptionType[]}
        required={isPackageFieldRequired}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {shouldShowSchedulerField && (
        <>
          <FormSelect
            name="schedulerId"
            placeholder="Choose a scheduler"
            simpleSearchPlaceholder="Search for schedulers..."
            options={filteredSchedulers?.map((scheduler) => ({
              value: scheduler.id,
              title: scheduler.title,
              description: `${scheduler.duration} minutes`,
              icon: scheduler.icon,
            }))}
            required={isSchedulerFieldRequired}
          />
          {renderCyclePreview}
        </>
      )}
    </div>
  );
};

export default AssignPackageSchedulerFormFields;
