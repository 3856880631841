import { useMemo } from "react";
import { ExtendedAccount } from "@practice/sdk";
import {
  getAssignClient,
  putAssignClient,
} from "api-services/definitions/clients";
import { useApi, useApiGet } from "api-services/endpoints";
import { AxiosResponse } from "axios";
import { MutatorCallback } from "swr";

import { useAuth } from "@contexts/auth";

import { useOrganizationAccounts } from "./use-organization-accounts";

type UseClientAssigneeType = (
  clientId: string,
  userId?: string
) => {
  clientAssignee?: ExtendedAccount;
  isLoading: boolean;
  isLoadingChange: boolean;
  mutate: MutatorCallback;
  changeAssignee: (
    accountId: string
  ) => Promise<AxiosResponse<{ status: string }> | undefined>;
};

const useClientAssignee: UseClientAssigneeType = (
  clientId,
  userId = undefined
) => {
  const { oid, aid } = useAuth();
  const { accounts: members, loading: isLoadingMembers } =
    useOrganizationAccounts();

  const {
    data: getAssignClientData,
    loading: isLoadingGetAssignClient,
    mutate,
  } = useApiGet(
    getAssignClient,
    {
      userId: userId || oid!,
      clientId,
    },
    {},
    {
      dedupingInterval: 600000,
    }
  );

  const { apiCall: changeAssignClient, loading: isLoadingChange } =
    useApi(putAssignClient);

  const clientAssignee = useMemo(() => {
    if (!getAssignClientData?.accountId || !members) return;

    return members.find(
      (member) => member.id === getAssignClientData.accountId
    );
  }, [getAssignClientData?.accountId, members]);

  const isLoading = useMemo(() => {
    return isLoadingGetAssignClient || isLoadingMembers;
  }, [isLoadingGetAssignClient, isLoadingMembers]);

  const changeAssignee = async (accountId: string) => {
    const assignedBy = aid !== accountId ? aid : undefined;
    return await changeAssignClient(
      { userId: oid!, clientId },
      { accountId, assignedBy },
      {}
    );
  };

  return {
    clientAssignee,
    isLoading,
    isLoadingChange,
    mutate,
    changeAssignee,
  };
};

export default useClientAssignee;
