import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  ActivityAction,
  ActivityApi,
  ActorType,
  ResourceType,
} from "@practice/sdk";

import { useAuth } from "@contexts/auth";
import useClientAssignee from "@hooks/use-client-assignee";
import { useOrganizationAccounts } from "@hooks/use-organization-accounts";
import { useRequestIdGenerator } from "@hooks/use-request-id-generator";
import { useSDKApi } from "@hooks/use-sdk-api";
import useSnackbar from "@hooks/use-snackbar";
import { displayNameFromContact } from "@lib/contacts";
import { ClientType } from "@lib/data/schemas/client";

import SelectFormMembers from "@components/Form/SelectFormMembers";
import AssignIcon from "@components/Icons/AssignIcon";
import Spinner from "@components/LoadingSpinner";
import SmallModal from "@components/Modals/SmallModal";

type FormDataType = {
  memberId: string;
};

type AssignClientToMemberModalProps = {
  show?: boolean;
  toggleShow?: (val: boolean) => void;
  client: ClientType;
};

const AssignClientToMemberModal: FC<AssignClientToMemberModalProps> = ({
  show,
  toggleShow,
  client,
}) => {
  const { oid, aid } = useAuth();
  const { accounts: members } = useOrganizationAccounts();
  const { clientAssignee, isLoading, isLoadingChange, mutate, changeAssignee } =
    useClientAssignee(client.id);

  const clientName = displayNameFromContact(client, true);
  const activityApi = useSDKApi(ActivityApi);
  const generateRequestId = useRequestIdGenerator(
    "components/Modals/AssignClientToMemberModal.tsx"
  );

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormDataType>();

  const snackbar = useSnackbar();

  useEffect(() => {
    if (clientAssignee) {
      setValue("memberId", clientAssignee.id);
    }
  }, [clientAssignee]);

  const handleUndo = async () => {
    if (!clientAssignee) return;
    const res = await changeAssignee(clientAssignee.id);
    if (res) {
      mutate();
      await activityApi.publishActivityMessage({
        publishActivityMessageRequest: {
          activityMessage: {
            action: ActivityAction.Relate,
            actorType: ActorType.Account,
            actorId: aid || "",
            resourceType: ResourceType.Account,
            resourceId: clientAssignee.id,
            organizationId: oid,
            clientId: client.id,
          },
        },
        xRequestId: generateRequestId(),
      });
      snackbar.showMessage("Client assignment successfully undone");
    }
  };

  const handleConfirm = async (data: FormDataType) => {
    if (!members) return;

    const member = members.find((member) => member.id === data.memberId);
    const memberName = displayNameFromContact(member, true);

    const res = await changeAssignee(data.memberId);

    if (res) {
      mutate();
      snackbar.showMessage(
        "Client assigned",
        `${clientName} is now assigned to ${memberName}`,
        "Undo",
        handleUndo
      );
      await activityApi.publishActivityMessage({
        publishActivityMessageRequest: {
          activityMessage: {
            action: ActivityAction.Relate,
            actorType: ActorType.Account,
            actorId: aid || "",
            resourceType: ResourceType.Account,
            resourceId: data.memberId,
            organizationId: oid,
            clientId: client.id,
          },
        },
        xRequestId: generateRequestId(),
      });
      toggleShow!(false);
    } else {
      snackbar.showWarning("Something went wrong", "Try again later");
    }
  };

  return (
    <SmallModal
      show={show}
      toggleShow={toggleShow}
      icon={AssignIcon}
      iconColor="blue"
      title="Assign client"
      description={`${clientName} will still be visible to everyone on your team.`}
      onActionLoading={isSubmitting || isLoadingChange}
      onAction={handleSubmit(handleConfirm)}
      onActionText="Confirm assignment"
      heapModalName="assign_client_modal"
      isActionDisabled={isLoading}
      isCloseAfterPrimaryActionDisabled
    >
      {isLoading || !members ? (
        <Spinner className="m-auto" />
      ) : (
        <SelectFormMembers
          label="Service provider"
          control={control}
          errors={errors}
          members={members}
        />
      )}
    </SmallModal>
  );
};

export default AssignClientToMemberModal;
