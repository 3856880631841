import { FC, useCallback } from "react";

import {
  useOrganizationAccounts,
  useOrganizationIntegrations,
} from "@hooks/use-organization-accounts";
import { getMatchingIntegration } from "@lib/utils/teams";

import ClientAvatar from "@components/Client/ClientAvatar";
import InfoIcon from "@components/Icons/InfoIcon";
import { Tooltip, TooltipSize } from "@components/Tooltips/Tooltip";

interface AvailabilityOwnerPreviewProps {
  calendarId?: string;
  accountId?: string;
}

export const useGetCalendarOwnerCallback = () => {
  const { accounts } = useOrganizationAccounts();
  const { integrations } = useOrganizationIntegrations();

  return useCallback(
    (calendarId?: string, accountId?: string) => {
      const matchingIntegration = getMatchingIntegration(
        integrations?.calendars,
        { id: calendarId, _email: accountId }
      );
      if (!matchingIntegration) return;
      return accounts?.find(
        (account) => account.id === matchingIntegration.memberId
      );
    },
    [accounts, integrations?.calendars]
  );
};

export const useGetCalendarOwner = (
  calendarId?: string,
  accountId?: string
) => {
  const getCalendarOwner = useGetCalendarOwnerCallback();

  if (!calendarId && !accountId) return;

  return getCalendarOwner(calendarId, accountId);
};

export const AvailabilityOwnerPreview: FC<AvailabilityOwnerPreviewProps> = ({
  calendarId,
  accountId,
}) => {
  const owner = useGetCalendarOwner(calendarId, accountId);

  if (!calendarId || !owner) return null;

  return (
    <div className="w-fit rounded-full flex space-x-3  bg-grey-950 items-center justify-start py-1 pl-1 pr-4">
      {/* TODO Share */}
      <ClientAvatar className="m-0" client={owner} />
      <div>{`${owner?.firstName}'s availability`}</div>
      <Tooltip
        className="text-grey-500 hover:text-black-ink"
        trigger={<InfoIcon />}
        size={TooltipSize.medium}
      >
        Based on calendar settings
      </Tooltip>
    </div>
  );
};
