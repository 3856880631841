import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { updateOrganizationAccount } from "api-services/definitions/accounts";
import { packageUpdatePatch } from "api-services/definitions/packages";
import { putUpdateScheduler } from "api-services/definitions/schedulers";
import { useApi } from "api-services/endpoints";
import { compact, isEmpty, isEqual, omit, pick } from "lodash";
import { useRouter } from "next/router";

import { useAuth } from "@contexts/auth";
import { useTheme } from "@contexts/theme";
import useAccount from "@hooks/use-account";
import useOrganization from "@hooks/use-organization";
import { useOrganizationAccounts } from "@hooks/use-organization-accounts";
import useWarnIfUnsavedChanges from "@hooks/use-warn-if-unsaved-changes";
import { AccountType } from "@lib/data/schemas/account";
import { getCoachBaseUrl } from "@lib/utils";

import { PublicItemType } from "@components/PublicProfile/PublicProfileSidebar/Pages/PublicItemCard";

import { getMemberPayload } from "./memberPublicProfile";
import {
  PageResourceType,
  PublicDataType,
  PublicProfileContextType,
  PublicProfileProviderProps,
  PublicProfileUserData,
} from "./types";

const PublicProfileContext = createContext<PublicProfileContextType>({
  data: null,
  updateData: async () => {},
  hasChangedSlug: false,
  hasUnsavedChanges: false,
  publishChanges: async () => {},
  updateCompanyData: async () => {},
  pageResource: null,
  setPageResource: () => {},
});

export const usePublicProfile = (): PublicProfileContextType => {
  return useContext(PublicProfileContext);
};

const PublicProfileProvider: FC<PublicProfileProviderProps> = ({
  children,
}) => {
  const { setTheme } = useTheme();
  const { oid } = useAuth();
  const { hasMoreThanOneMember } = useOrganizationAccounts();
  const { update: updateOrgData } = useOrganization(oid, true);
  const { apiCall: updateScheduler } = useApi(putUpdateScheduler);
  const { apiCall: updatePackage } = useApi(packageUpdatePatch);
  const { apiCall: updateMember } = useApi(updateOrganizationAccount);
  const router = useRouter();
  const { update: updateOwnerAccount } = useAccount(oid);
  const [pageResource, setPageResource] = useState<PageResourceType>(null);
  const [data, setData] = useState<PublicDataType | null>(null);
  const [initialData, setInitialData] = useState<PublicDataType | null>(null);

  const hasChangedSlug = data?.slug !== initialData?.slug;

  const hasUnsavedChanges = initialData
    ? !isEqual(omit(data, "pendingDomains"), initialData)
    : false;

  const schedulersChanged = !isEqual(data?.schedulers, initialData?.schedulers);
  const packagesChanged = !isEqual(data?.packages, initialData?.packages);
  const membersChanged = !isEqual(data?.members, initialData?.members);

  const pickFields = ["publicOrder", "secret"];

  const getBody = (item: PublicItemType, original: PublicItemType) => {
    const newItem = pick(item, pickFields);
    const originalItem = pick(original, pickFields);
    if (!isEqual(originalItem, newItem)) {
      const { publicOrder, secret } = newItem;
      return {
        ...(publicOrder !== undefined && { publicOrder }),
        ...(secret !== undefined && { secret }),
      };
    }
    return {};
  };

  useWarnIfUnsavedChanges({
    isDirty: hasUnsavedChanges,
    message: "You have unpublished changes. Are you sure you want to leave?",
  });

  const updateData = useCallback(
    async (updatedData: Partial<PublicDataType>) => {
      setData((data) => ({ ...data!, ...updatedData }));

      const themeData = pick(updatedData, ["color", "isDynamicTheme", "font"]);
      if (themeData) setTheme((theme) => ({ ...theme, ...themeData }));
    },
    [setTheme]
  );

  const updateCompanyData = (
    payload: Partial<PublicProfileUserData["companyDetails"]>
  ) => updateData({ companyDetails: { ...data?.companyDetails, ...payload } });

  const publishChanges = useCallback(async () => {
    if (!data) return;
    const { schedulers, packages, members, ...user } = data;

    const accountDetails = {
      firstName: user.firstName,
      lastName: user.lastName,
      ...(user.avatarURL && { avatarURL: user.avatarURL }),
    };

    const ignoreFields = [
      "featureNames",
      "email",
      "id",
      "memberAccountIds",
      "stripe",
      "timeZone",
    ];

    // update user
    await updateOrgData(omit({ ...user, updatedAt: new Date() }, ignoreFields));
    if (!hasMoreThanOneMember) await updateOwnerAccount(accountDetails);

    // update schedulers
    if (schedulersChanged) {
      const updateSchedulerPromises = compact(
        schedulers?.map((s) => {
          const original = initialData?.schedulers?.find((i) => i.id === s.id);
          const body = getBody(s, original as PublicItemType);

          if (isEmpty(body)) return null;
          else
            return updateScheduler(
              { userId: oid!, schedulerId: s.id, apiVersion: "v1" },
              body,
              {}
            );
        })
      );
      Promise.all(updateSchedulerPromises);
    }

    // update packages
    if (packagesChanged) {
      const updatePackagesPromises = compact(
        packages?.map((p) => {
          const original = initialData?.packages?.find((i) => i.id === p.id);
          const body = getBody(p, original as PublicItemType);

          if (isEmpty(body)) return null;
          else
            return updatePackage({ userId: oid!, packageId: p.id }, body, {});
        })
      );
      Promise.all(updatePackagesPromises);
    }

    // update members
    if (membersChanged) {
      const updateMembersPromises = compact(
        members?.map((m) => {
          const original = initialData?.members?.find((i) => i.id === m.id);
          const body = getMemberPayload(m, original as AccountType);

          if (isEmpty(body)) return null;
          else return updateMember({ userId: oid!, accountId: m.id }, body, {});
        })
      );
      Promise.all(updateMembersPromises);
    }

    const updatedSlug = user?.slug !== initialData?.slug;

    setInitialData(data);

    if (updatedSlug) {
      router.push(`${getCoachBaseUrl(user)}`);
    }
  }, [
    data,
    updateOrgData,
    hasMoreThanOneMember,
    updateOwnerAccount,
    initialData?.slug,
    updateScheduler,
    oid,
    updatePackage,
    router,
  ]);

  useEffect(() => {
    if (initialData) return;

    setInitialData(data);
  }, [data, initialData]);

  return (
    <PublicProfileContext.Provider
      value={{
        data,
        updateData,
        hasChangedSlug,
        hasUnsavedChanges,
        publishChanges,
        updateCompanyData,
        pageResource,
        setPageResource,
      }}
    >
      {children}
    </PublicProfileContext.Provider>
  );
};

export { PublicProfileContext, PublicProfileProvider };
