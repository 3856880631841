import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { GroupScheduler } from "@practice/sdk";
import {
  collection,
  collectionGroup,
  doc,
  onSnapshot,
  orderBy,
  query,
  QueryConstraint,
  where,
} from "firebase/firestore";
import { cloneDeep, noop } from "lodash";
import useSWRSubscription from "swr/subscription";
import { GroupType } from "types/db/client";
import { File } from "types/db/file";
import { Folder } from "types/db/folder";
import { FormTemplate } from "types/db/form";
import { Label } from "types/db/label";
import { Link } from "types/db/link";
import { firebaseDataMapper, SetState } from "types/utils";

import { ApiKeysType } from "@lib/data/schemas/api-keys";
import { AppointmentType } from "@lib/data/schemas/appointment";
import { AvailabilityBlockType } from "@lib/data/schemas/availability-block";
import { ClientType } from "@lib/data/schemas/client";
import { CurrencyType } from "@lib/data/schemas/currency";
import { InternalFormType } from "@lib/data/schemas/internal-form";
import { InvoiceType } from "@lib/data/schemas/invoice";
import { SchedulerType } from "@lib/data/schemas/scheduler";
import { SubscriptionType } from "@lib/data/schemas/subscription";
import { SubscriptionPlanType } from "@lib/data/schemas/subscription-plan";
import { TaxTypeType } from "@lib/data/schemas/taxType";
import { compatDB as db } from "@lib/firebase-config";

import { useAuth } from "./auth";

export type CollectionTypeMap = {
  clients: ClientType;
  groups: GroupType;
  files: File;
  links: Link;
  folders: Folder;
  labels: Label;
  form_templates: FormTemplate;
  schedulers: SchedulerType;
  invoices: InvoiceType;
  subscriptions: SubscriptionType;
  subscriptionPlans: SubscriptionPlanType;
  apiKeys: ApiKeysType;
  availabilityBlocks: AvailabilityBlockType;
  users: any;
  currencies: CurrencyType;
  taxTypes: TaxTypeType;
  appointments: AppointmentType;
  internal_forms: InternalFormType;
  groupSchedulers: GroupScheduler;
};

export type CollectionNames = keyof CollectionTypeMap;

type Data = Partial<
  Record<CollectionNames, CollectionTypeMap[CollectionNames][]>
>;

type ContextData = {
  groupCollection: Data;
  collection: Data;
};

const init = { groupCollection: {}, collection: {} };

type GetIsLoadedSignature = (
  name: CollectionNames,
  kind: keyof ContextData
) => boolean;
type LoadCollectionSignature = (
  name: CollectionNames,
  kind: keyof ContextData
) => void;
const DataContext = createContext<{
  data: ContextData;
  setData: SetState<ContextData>;
  getIsLoaded: GetIsLoadedSignature;
  loadCollection: LoadCollectionSignature;
}>({
  data: cloneDeep(init),
  setData: noop,
  getIsLoaded: () => false,
  loadCollection: noop,
});

export const DataProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [data, setData] = useState<ContextData>(cloneDeep(init));
  const { uid } = useAuth();
  const loadInfo = useRef<{
    collection: Partial<Record<CollectionNames, boolean>>;
    groupCollection: Partial<Record<CollectionNames, boolean>>;
  }>(cloneDeep(init));

  const getIsLoaded: GetIsLoadedSignature = useCallback(
    (name, kind) => !!loadInfo.current[kind][name],
    []
  );
  const loadCollection: LoadCollectionSignature = useCallback(
    (name, kind) => (loadInfo.current[kind][name] = true),
    []
  );

  useEffect(() => {
    if (!uid) {
      setData(cloneDeep(init));
      loadInfo.current = cloneDeep(init);
      return;
    }

    const unsub = onSnapshot(doc(db, `users/${uid}`), (doc) => {
      if (doc.exists())
        setData((ex) => ({
          ...ex,
          collection: {
            ...ex.collection,
            users: [firebaseDataMapper(doc)],
          },
        }));
    });

    return unsub;
  }, [uid]);

  return (
    <DataContext.Provider
      value={{
        data,
        setData,
        getIsLoaded,
        loadCollection,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

const useDataContext = () => useContext(DataContext);

type QueryOptions = {
  groupPropName?: string;
  baseCollection?: boolean;
  orderByCreatedAt?: boolean;
};

export const useCollection = <T extends CollectionNames>(
  name: T,
  options?: QueryOptions
): { loading: boolean; data?: CollectionTypeMap[T][] } => {
  const { data, setData, loadCollection, getIsLoaded } = useDataContext();

  const { uid } = useAuth();

  const kind = !options?.groupPropName ? "collection" : "groupCollection";

  const val = useMemo(
    () => data[kind][name],
    [data, kind, name]
  ) as CollectionTypeMap[T][];

  const load = useCallback(async () => {
    loadCollection(name, kind);
    const isCollectionGroup =
      !!options?.groupPropName && !options?.baseCollection;
    const base = isCollectionGroup ? collectionGroup : collection;
    const path =
      options?.baseCollection || isCollectionGroup
        ? name
        : `users/${uid}/${name}`;
    const queryConstraints: QueryConstraint[] = [];
    if (options?.groupPropName)
      queryConstraints.push(where(options?.groupPropName, "==", uid));

    const shouldOrderByCreatedAt = options?.orderByCreatedAt ?? true;
    if (shouldOrderByCreatedAt)
      queryConstraints.push(orderBy("createdAt", "desc"));

    const q = query(base(db, path), ...queryConstraints);

    const unsubscribe = onSnapshot(
      q,
      (d) => {
        setData((ex) => {
          return {
            ...ex,
            [kind]: {
              ...ex[kind],
              [name]: d.docs
                .filter(
                  (d) => d.data().status !== "deleted" && !d.data().isDeleted
                )
                .map(firebaseDataMapper),
            },
          };
        });
      },
      (e) => {
        console.log("Error reading firebase data", { e, name, kind, options });
        throw e;
      }
    );

    return unsubscribe;
  }, [loadCollection, name, kind, options, uid, setData]);

  useEffect(() => {
    if (!uid || getIsLoaded(name, kind)) return;

    load();
  }, [name, load, uid, getIsLoaded, kind]);

  return { loading: !val, data: val };
};

export const useDocument = <T extends CollectionNames>(
  collection: T,
  id?: string,
  options?: QueryOptions
): { loading: boolean; data?: CollectionTypeMap[T] } => {
  const { loading, data } = useCollection(collection, options);
  return {
    loading,
    data: data?.find((x) => x.id == id) as CollectionTypeMap[T],
  };
};

export const useClientDocument = <T extends CollectionNames>(
  collection: T,
  clientId?: string,
  id?: string
): { loading: boolean; data?: CollectionTypeMap[T] } => {
  const [data, setData] = useState<CollectionTypeMap[T]>();

  const { uid } = useAuth();

  useEffect(() => {
    if (!clientId || !id || !uid) return;

    const unsub = onSnapshot(
      doc(db, `/users/${uid}/clients/${clientId}/${collection}/${id}`),
      (doc) => {
        if (doc.exists())
          setData(firebaseDataMapper(doc) as CollectionTypeMap[T]);
      }
    );

    return unsub;
  }, [clientId, collection, id, uid]);

  return {
    loading: !data,
    data,
  };
};

export const useClientCollection = <T extends CollectionNames>(
  collectionName: T,
  clientId?: string
): { loading: boolean; data?: CollectionTypeMap[T][] } => {
  const [data, setData] = useState<CollectionTypeMap[T][]>();

  const { uid } = useAuth();

  useEffect(() => {
    if (!clientId || !uid) return;

    const unsub = onSnapshot(
      collection(db, `users/${uid}/clients/${clientId}/${collectionName}`),
      (snap) =>
        setData(snap.docs.map(firebaseDataMapper) as CollectionTypeMap[T][])
    );

    return unsub;
  }, [clientId, collectionName, uid]);

  return {
    loading: !data,
    data,
  };
};

export const useGetFirestoreCollectionData = ({
  collectionName,
  queryList,
  isCollectionGroup,
  order,
}: {
  collectionName: string | undefined;
  queryList: Parameters<typeof where>[];
  order?: Parameters<typeof orderBy>;
  isCollectionGroup?: boolean;
}) => {
  const key = collectionName
    ? `${collectionName}-${JSON.stringify(queryList)}`
    : undefined;

  const constraints: QueryConstraint[] = queryList.map((items) =>
    where(...items)
  );

  const base = isCollectionGroup ? collectionGroup : collection;

  if (order) constraints.push(orderBy(...order));

  return useSWRSubscription(key, (_, { next }) => {
    if (!collectionName) return;

    const q = query(base(db, collectionName), ...constraints);

    const sub = onSnapshot(
      q,
      (result) => {
        next(null, result.docs.map(firebaseDataMapper));
      },
      (error) => {
        console.log("Error loading", error);
      }
    );

    return sub;
  });
};

export const useGetFirestoreDocumentData = (documentPath?: string) => {
  return useSWRSubscription(documentPath, (_, { next }) => {
    if (!documentPath) return;

    const sub = onSnapshot(
      doc(db, documentPath),
      (result) => {
        if (result.exists()) next(null, firebaseDataMapper(result));
      },
      (error) => {
        console.log("Error loading", error);
      }
    );

    return sub;
  });
};
